import * as appConfig from '../app/config/app.config';

export const environment = {
  production: false,
  googleAnalytics: {
    domain: "auto",
    trackingId: "UA-47779257-4" // replace with your Tracking Id
  },
  googleMapParams: {
    // clientId: "gme-ageroadministrative",
    apiKey: "AIzaSyBF5OWBMRuegDsOT4h9RbzrN5X1_x1dQi0", //Stage key
    //apiKey: "AIzaSyA-4CrO9gGiBwGMdeDa-apzR6TXWcVm72Y", //PROD Key
    libraries: ["geometry","places", "drawing"]
  },
  recaptcha: {
    
    siteKey:"6Leuy2oeAAAAAH4IMP_SgD_v1-sSgZPh-8OzKGzE"
  },
  logging: true,
  logSuccess: true,
  AppConfig: appConfig && appConfig.AppConfig,
  envName: "STAGE"
};
