import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-safeco',
  templateUrl: './safeco.component.html',
  styleUrls: ['./safeco.component.css']
})
export class SafecoComponent implements OnInit {
  clientId: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.clientId = params.id;
      });
  }
}
