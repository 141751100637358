import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pp',
  templateUrl: '../../../../html/toc.html'  
})
export class PpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
