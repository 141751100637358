import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PpComponent } from './pp/pp.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PpComponent]
})
export class PpModule { }
