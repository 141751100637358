import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {LoggerService} from './shared/logger.service';
import {SharedModule} from '../shared/modules/shared.module';
import {RouterModule} from '@angular/router';
import {Error404Component} from './error404/error-404.component';
import {ProgressBarService} from './shared/progress-bar.service';
import {PhoneFormatPipe} from '../rsa-decider/pipes/phone.pipe.us';
import {PhoneMaskDirective} from './shared/phone-format.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
  
  ],
  declarations: [   
    Error404Component,
    PhoneFormatPipe,
    PhoneMaskDirective
  ],
  providers: [
    LoggerService,
    ProgressBarService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
