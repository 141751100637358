import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import "rxjs/add/operator/map";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";

import { RsaDeciderService } from "../shared/rsadecider-service";
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AppConfig } from "../../config/app.config";
import { environment } from "src/environments/environment";

@Component({
  selector: "rsa-dialog",
  templateUrl: "./rsa-dialog.component.html"
})
export class RsaDialog implements OnInit {
  public appDataConfig;
  public rsaProgressData;
  public rsaProgressMenu;
  public scheduleLater: boolean;
  public step2Form: FormGroup;
  public screenType: any;
  nonivrflow: boolean;
  public updateProfileSuccess:boolean;
  public skipVinOEM:boolean;
  public profileSearchError:boolean;
  public title:any;
  public content:any;
  public continue:any;
  public cancel:any;
  encaptchaEnabled:boolean = false;
  public displayRecaptchaMsg:boolean = false;
  public isProfileNotEnabled:boolean = false;
  public isRsaHelpRequestIdFlow:boolean = false;
  @Output() formStatus = new EventEmitter();
  // @ViewChild("phonenumber") phonenumber;
  phoneNumberGroup = new FormGroup({
    phoneNumber1: new FormControl("", Validators.required),
    phoneNumber2: new FormControl("", Validators.required),
    phoneNumber3: new FormControl("", Validators.required),
    phoneNumber: new FormControl("", Validators.required)
  });
  public selectedReason: string;

  constructor(
    private actroute: ActivatedRoute,
    public RsaDeciderService: RsaDeciderService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MatDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private _translateService: TranslateService
  ) {
    this.actroute.data
      .map(data => data.cres)
      .subscribe(res => {
        this.RsaDeciderService.setRsaConfig(res);
      });
      this.screenType = data;
  }

  ngOnInit() {
    this.appDataConfig = this.RsaDeciderService.getRsaConfig();
    this.cdr.detectChanges();
    this.rsaProgressData = this.RsaDeciderService.getRSAData();
    this.rsaProgressMenu = this.rsaProgressData[
      this.rsaProgressData.step1.menu
    ];
    this.isRsaHelpRequestIdFlow = JSON.parse(localStorage.getItem("isRsaHelpRequestIdFlow"));
    this.isProfileNotEnabled = (this.appDataConfig && this.appDataConfig.isProfileNotEnabled) || (this.isRsaHelpRequestIdFlow) ;
    this.formValueChanges();
    this.valueOnLoad();
    this.title = this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('dialog') ? this.appDataConfig['styles']['dialog']['title']['color'] : '#000000';
    this.content = this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('dialog') ? this.appDataConfig['styles']['dialog']['content']['color'] : '#000000';
    this.continue = this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('dialog') ? this.appDataConfig['styles']['dialog']['continue']['background-color'] : (this.appDataConfig.color ? this.appDataConfig.color : '#006ce6' );
    this.cancel = this.appDataConfig.hasOwnProperty('styles') && this.appDataConfig['styles'].hasOwnProperty('dialog') ? this.appDataConfig['styles']['dialog']['cancel']['color'] : this.appDataConfig.color;
    this.nonivrflow = JSON.parse(localStorage.getItem('nonIvrFlow'));
    this.skipVinOEM = JSON.parse(localStorage.getItem('skipVinOEM'));
    this.updateProfileSuccess = JSON.parse(localStorage.getItem('vinOEMUpdateProfileCalled'));
    this.profileSearchError = this.rsaProgressData.profileSearchError;
  }

  valueOnLoad() {
    let menu = this.rsaProgressData.step1.menu;
    let phoneNumber;
    if (this.rsaProgressData.callbacknumber_fromurl) {
      phoneNumber = this.rsaProgressData.callbacknumber_fromurl;
    } else if (
      menu &&
      menu != "accident_menu" &&
      menu != "dontknow_menu" &&
      this.rsaProgressMenu.step5.phonenumber
    ) {
      phoneNumber = this.rsaProgressMenu.step5.phonenumber;
    } else if (this.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId) {
      phoneNumber = this.rsaProgressData.rsaHelpCallBackNumber;
    }

    if (phoneNumber) {
      this.phoneNumberGroup
        .get("phoneNumber1")
        .setValue(phoneNumber.split("-")[0]);
      this.phoneNumberGroup
        .get("phoneNumber2")
        .setValue(phoneNumber.split("-")[1]);
      this.phoneNumberGroup
        .get("phoneNumber3")
        .setValue(phoneNumber.split("-")[2]);
    }
  }

  formValueChanges() {
    this.phoneNumberGroup.valueChanges.subscribe(() => {
      let contructedPhoneNumber =
        this.phoneNumberGroup.get("phoneNumber1").value +
        "-" +
        this.phoneNumberGroup.get("phoneNumber2").value +
        "-" +
        this.phoneNumberGroup.get("phoneNumber3").value;
      this.phoneNumberGroup
        .get("phoneNumber")
        .setValue(contructedPhoneNumber, { emitEvent: false });
    });
  }

  phoneValidation(e) {
    if (e.srcElement.maxLength === e.srcElement.value.length) {
      // e.preventDefault();

      let nextControl: any = e.srcElement.nextElementSibling.nextElementSibling;
      // Searching for next similar control to set it focus
      if (nextControl) {
        if (nextControl.type === e.srcElement.type) {
          nextControl.focus();
          return;
        }
      } else {
        return;
      }
    } else if (!e.srcElement.value.length) {
      let nextControl: any = e.srcElement.previousElementSibling ? e.srcElement.previousElementSibling.previousElementSibling : e.srcElement.previousElementSibling;
      // Searching for next similar control to set it focus
      if (nextControl) {
        if (nextControl.type === e.srcElement.type) {
          nextControl.focus();
          return;
        }
      } else {
        return;
      }
    }
  }
  numberOnly(e) {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (e.target.id == "firstThree" && e.target.value.length == 0 && e.which == 48) {
      return false;
    }
  }

  selectClient(client) {
    window.location.href =
      window.location.href + "?num=" + client.currentTarget.id;
  }
  selectAudioType(audio) {
    this.rsaProgressData.audio_type = audio.currentTarget.id;
    this.RsaDeciderService.setRSAData(this.rsaProgressData);
    if (audio.currentTarget.id == "0" || audio.currentTarget.id == "3") {
      this.RsaDeciderService.playAudio("1");
    }
  }
  confirm(type) {
    let msg;
    switch (type) {
      case "call": {
          this.rsaProgressData.loadingScreen = true;
          let isUISubmitInitiated = this.RsaDeciderService.liveCallStatus ? true : false;  
          // make saveRSA call to check the call is active or not
          this.RsaDeciderService.saveTrackingRSARequest('', isUISubmitInitiated).subscribe((data) => {
            if (this.rsaProgressData.trackingIdFlags.hasCallBack && this.RsaDeciderService.liveCallStatus) {
              this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = true;
              this.processSubmission();
            } else {
              this.rsaProgressData.loadingScreen = false;
              this.openDialog("callbackno");
            }
          });
          if(!this.rsaProgressData.trackingIdFlags.isPhoneTapped && this.nonivrflow){
            msg = "User confirmed the call on submitting request for non ivr flow"
          } else {
           msg = "User confirmed the call after tapping phone icon";
          }
          this.RsaDeciderService.rsaProgressData.pageRedirects = false;
          break;
      }
      case "dontknow": {
        msg = "User confirmed the call after tapping I don't know option";
        break;
      }
      case "accident": {
          let isUISubmitInitiated = this.RsaDeciderService.liveCallStatus ? true : false; 
          // make saveRSA call to check the call is active or not
          this.RsaDeciderService.saveTrackingRSARequest('', isUISubmitInitiated).subscribe((data) => {
            if (this.rsaProgressData.trackingIdFlags.hasCallBack && this.RsaDeciderService.liveCallStatus) {
              this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = true;
              this.processSubmission();
            } else {
              this.openDialog("callbackno");
            }
          });
          msg = "User confirmed the call after tapping accident option";
          break;
      }
      case "location": {
        this.dialogRef.close({ data: 'user confirmed the skip' })
        msg = "User is not sure of location and so he skipped";
        break;
      }
      case "alert": {
        msg = "User has received success or failure response";
        break;
      }
      case "location-trouble": {
        msg = "User has read the location trouble passage";
        break;
      }
      case "terms": {
        msg = "User accepted the terms and condition";
        break;
      }
      case "policy": {
        msg = "User reads the privacy & policy information";
        break;
      }
      case "gps": {
        msg = "User understands the need of location";
        break;
      }
      case "callbackno": {
          this.rsaProgressData.loadingScreen = true;
          let menu = this.rsaProgressData.step1.menu;
          let number;
          if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
            number = this.rsaProgressMenu.step5.phonenumber = this.rsaProgressData.callbacknumber_fromurl = this.phoneNumberGroup.get("phoneNumber").value;
          } else {
            number = this.rsaProgressData.callbacknumber_fromurl = this.phoneNumberGroup.get("phoneNumber").value;
          }
          let isUISubmitInitiated = this.RsaDeciderService.liveCallStatus ? true : false; 
          // make saveRSA call to check the call is active or not
          this.RsaDeciderService.saveTrackingRSARequest('', isUISubmitInitiated).subscribe((data) => {
              this.processSubmission();
              msg = "User provides the call back number";
          });
          break;
        }
        //RS1610 Feature Implementation NonIVR Transitioning to VIVR experience
        case "nonivrcallbackno": {
          this.rsaProgressData.loadingScreen = false;
          let menu = this.rsaProgressData.step1.menu;
          this.rsaProgressData.callbacknumber_fromurl = this.phoneNumberGroup.get("phoneNumber").value;
          
            this.openDialog('encaptcha');
            msg = "User provides the callback number for non ivr flow";
          break;
          }
          //RS1610 Feature Implementation NonIVR Transitioning to VIVR experience
          case "encaptcha":{
            this.rsaProgressData.loadingScreen = false;
            this.rsaProgressData.isEncaptchaSuccess = true;
            let menu = this.rsaProgressData.step1.menu;
            let number;
            if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu") {
              number = this.rsaProgressMenu.step5.phonenumber = this.rsaProgressData.callbacknumber_fromurl ;
            } else {
              number = this.rsaProgressData.callbacknumber_fromurl;
            }
            this.getJobId();
            break;
          }
      case "tollFreeNo": {
        msg = "User taps on toll free number to connect with rep";
        break;
      }
    }
    this.RsaDeciderService.nonAPISplunkLog(msg);
  }

  denied(type) {
    let msg;
    switch (type) {
      case "call": {
        this.rsaProgressData.trackingIdFlags.isPhoneTapped = false;
        this.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = false;
        msg = "User denied the call after tapping phone icon";
        break;
      }
      case "dontknow": {
        msg = "User denied the call after tapping I don't know option";
        break;
      }
      case "accident": {
        msg = "User denied the call after tapping accident option";
        break;
      }
      case "callbackno": {
        this.rsaProgressData.trackingIdFlags.isPhoneTapped = false;
        msg= "User has not provided the callback number";
        break;
      }
      case "tollFreeNo": {
        msg = "User denied to make a call with rep";
        break;
      }
      case "location": {
        this.dialogRef.close({ data: 'user cancelled the skip' })
        break;
      }
    }
    this.RsaDeciderService.nonAPISplunkLog(msg);
  }

  openDialog(type) {
    let message;
    if(this._translateService.currentLang =="en")
    message ="Your request was unsuccessful. Please submit again, or call " + this.appDataConfig.number + " for service.";
    else
    message ="Ihre Anfrage war nicht erfolgreich. Bitte nochmal absenden oder anrufen " + this.appDataConfig.number + " für den Service.";
    const dialogRef = this.dialog.open(RsaDialog, {
      width: "320px",
      closeOnNavigation: true,
      disableClose: true,
      data: { type: type, appDataConfig: this.appDataConfig, message: message, screen: this.screenType.screen, flag: this.screenType.flag }
    });
    this.dialogRef.close();
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  submitRequest(): void {
    if(this.getJobStatus()){ // Policy or VIN is skipped
      this.RsaDeciderService.submitNgpNonIvrFlow(true).subscribe((result: any) => {
        if (result.body.rsaRequestId) {
          this.rsaProgressData.step1.rsaRequestId = result.body.rsaRequestId;

          this.rsaProgressData.trackingIdFlags.isTrackingDetails = true;
          this.RsaDeciderService.saveTrackingRSARequest(true).subscribe((data) => {
            this.rsaProgressData.loadingScreen = false;
            if (data) {
              localStorage.removeItem('nonIvrFlow');
              this.router.navigate(["/submit-success", this.RsaDeciderService.getRsaTrackingId()]);
            } else {
              this.errorResponse();
            }
          }, (err: any) => {
            this.errorResponse();
          });
        } else {
          this.errorResponse();
        }
      }, (err: any) => {
        this.errorResponse();
      });
    } else{ // valid policy or vin
    this.RsaDeciderService.submitNgpIvrFlow().subscribe((result) => {
      if (result) {
        this.rsaProgressData.trackingIdFlags.isTrackingDetails = true;
        this.rsaProgressData.step1.rsaRequestId = result.id ? result.id : '';
        this.rsaProgressData.loadingScreen = false;
        localStorage.removeItem('nonIvrFlow');
        this.rsaProgressData.payment.isPaymentReqd = this.rsaProgressData.trackingIdFlags.isPhoneTapped ? false : this.rsaProgressData.payment.isPaymentReqd;
        if(this.rsaProgressData.payment.isPaymentReqd && result.hasOwnProperty('customerWebLink')
        && result["customerWebLink"] != null && result["customerWebLink"] != "" ){
          this.RsaDeciderService.rsaProgressData.pageRedirects= false;
          this.RsaDeciderService.nonAPISplunkLog("Redirected to payment page",result.customerWebLink);
          window.location.href = (result.customerWebLink.indexOf('http') != -1) ? result.customerWebLink : `https://${result.customerWebLink}`;
        } else {
          this.router.navigate(["/submit-success", this.RsaDeciderService.getRsaTrackingId()]);
        }
        // this.RsaDeciderService.saveTrackingRSARequest(true).subscribe((data) => {
        //   this.rsaProgressData.loadingScreen = false;
        //   if (data) {
        //     localStorage.removeItem('nonIvrFlow');
        //     this.router.navigate(["/submit-success", this.RsaDeciderService.getRsaTrackingId()]);
        //   } else {
        //     this.errorResponse();
        //   }
        // }, (err: any) => {
        //   this.errorResponse();
        // });
      } else {
        this.errorResponse();
      }
    }, (err: any) => {
      this.errorResponse();
    });
  }
  }

  processSubmission() {
    if (!this.isProfileNotEnabled) {
      this.rsaProgressData.loadingScreen = true;
      if (this.screenType.screen == 'reviewScreen') {
        this.submitRequest();
      } else {
        if (this.getJobStatus()) {
          this.RsaDeciderService.nonAPISplunkLog("Skipped Coverage Search For OEM SkipVin");
          this.submitRequest();
        }
        else {
          this.RsaDeciderService.coverageSearch(true).subscribe((data) => {
            if (data) {
              this.submitRequest();
            } else {
              this.errorResponse();
            }
          }, (err: any) => {
            this.errorResponse();
          });
        }
      }
    } 
    else if (this.isProfileNotEnabled) { // onstar and midvale
      this.rsaProgressData.loadingScreen = true;
      this.RsaDeciderService.submitNgpNonIvrFlow(true).subscribe((result: any) => {
        if (result.body.rsaRequestId) {
          this.ngpnonIVR(result.body.rsaRequestId);
        } else {
          this.errorResponse();
        }
      }, (err: any) => {
        this.errorResponse();
      });
    } 
  }

  errorResponse(): void {
    this.rsaProgressData.loadingScreen = false;
    this.rsaProgressData.trackingIdFlags.isPhoneTapped = false;
    this.rsaProgressData.trackingIdFlags.hasCallbackAndPhoneTap = false;
    this.openDialog('alert');
  }

  ngpnonIVR(id) {
    if (id) {
      this.rsaProgressData.trackingIdFlags.isTrackingDetails = true;
      this.rsaProgressData.step1.rsaRequestId = id? id: '';
      this.RsaDeciderService.saveTrackingRSARequest(true).subscribe((data) => {
        this.rsaProgressData.loadingScreen = false;
        if (data) {
          localStorage.removeItem('nonIvrFlow');
          this.router.navigate(["/submit-success", this.RsaDeciderService.getRsaTrackingId()]);
        } else {
          this.errorResponse();
        }
      },
        (err: any) => {
          this.errorResponse();
        });
    } else {
      this.errorResponse();
    }
  }

  displayAlert(){

    let message;
    if(this._translateService.currentLang =="en")
    message ="Your request was unsuccessful. Please submit again, or call " + this.appDataConfig.number + " for service.";
    else
    message ="Ihre Anfrage war nicht erfolgreich. Bitte nochmal absenden oder anrufen " + this.appDataConfig.number + " für den Service.";
    const dialogRef = this.dialog.open(RsaDialog, {
      width: '320px',
      closeOnNavigation:true,
      disableClose:true,
      data: {type:'alert',appDataConfig:this.appDataConfig,message:message}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');      
    });
  }

  getJobStatus(){
    let menu = this.rsaProgressData.step1.menu;
    if (menu && menu != "accident_menu" && menu != "dontknow_menu" && menu != "coverage_menu"){
    if(this.appDataConfig.type == "AUTO" && (this.rsaProgressData[menu].step6.skipVin || this.profileSearchError)){
      return true;
    } else if(this.appDataConfig.type == "INS" && (this.rsaProgressData[menu].step5.skipPolicy || this.profileSearchError)){
      return true;
    } else {
    return false; 
    }
   }
    return false;
  }

  //RS1610 Feature Implementation NonIVR Transitioning to VIVR experience
  openEncaptchaDialog(type) {
   const dialogRef = this.dialog.open(RsaDialog, {
      width: "320px",
      closeOnNavigation: true,
      disableClose: true,
      data: { type: type, appDataConfig: this.appDataConfig, screen: this.screenType.screen, flag: this.screenType.flag }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  getJobId(): void {
    if ((this.rsaProgressData.step1.JobId == null || this.rsaProgressData.step1.JobId == '') && this.nonivrflow) {
      this.RsaDeciderService.preDraftJob().subscribe((result) => {
        this.RsaDeciderService.loadChatBubble(true,'Service Selection');
        if (this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasCallBack)
          this.RsaDeciderService.saveTrackingRSARequestClick();
      });
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.RsaDeciderService.nonAPISplunkLog(captchaResponse);
    if (captchaResponse != null) {
      this.RsaDeciderService.recaptchaSiteVerify(captchaResponse).subscribe((res) => {
        if (res && res.Result && res.Result.TokenProperties && (res.Result.Score != 0 && res.Result.Score != 0.0)) {
          let tokenProperties = res.Result.TokenProperties;
          if (tokenProperties.hasOwnProperty("Action") && tokenProperties["Action"] == 'login' &&
            tokenProperties.hasOwnProperty("Valid") && tokenProperties.Valid) {
            this.encaptchaEnabled = true;
            this.displayRecaptchaMsg = false;
            this.rsaProgressData.encaptchaSuccess = true;
          } else {
            this.displayRecaptchaMsg = true;
            grecaptcha.reset();
            this.encaptchaEnabled = false;
          }
        } else {
          this.displayRecaptchaMsg = true;
          grecaptcha.reset();
          this.encaptchaEnabled = false;
        }
      }, (err: any) => {
        if (err) {
          this.displayRecaptchaMsg = true;
          grecaptcha.reset();
          this.encaptchaEnabled = false;
        }
      });
    } else {
      this.encaptchaEnabled = false;
    }
  }

  logReasons(event) {
    this.selectedReason = event.value;
    this.RsaDeciderService.nonAPISplunkLog("Reason for connecting with representative : " + this.selectedReason, this.selectedReason);
  }

  openChatWindow() {
    document.getElementById("converge-one-chat-application-iframe").style.display = "block";
    this.RsaDeciderService.openChat();
    this.registerChatCallback('endChat', this.cleanupChatWindow, 1);
    this.dialogRef.close();
  }

  registerChatCallback(event, callback, index) {
    const windowRef: any = window;
    var pollingIndex = index !== undefined && typeof index === 'number' ? index : 0;
    var duration = 1000;
    var frequency = 10;
    var logIntro = "Chat Session Callback Started - ";
    var logDuration = (pollingIndex * frequency).toString() + " ms";
    if (pollingIndex < duration) {
      if (windowRef && windowRef.registerC1WebChatCallback) {
        windowRef.registerC1WebChatCallback(event, callback);
        console.log(logIntro + "successful at " + logDuration);
        this.RsaDeciderService.nonAPISplunkLog(logIntro + "successful at " + logDuration);
      } else {
        this.registerChatCallback(event, callback, pollingIndex + 1);
      }
    } else {
      this.RsaDeciderService.nonAPISplunkLog(logIntro + "unsuccessful after " + logDuration);
    }
  }; 

  cleanupChatWindow(){ 
    document.getElementById("converge-one-chat-application-iframe").style.display = "none";
    console.log("Closing Chat Window");
  }
}
