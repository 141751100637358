import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';

@Directive({
  selector: '[formControlName][phoneUSFormat]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy {

  private _preValue: string;

  @Input()
  set preValue(value: string) {
    this._preValue = value;
  }

  private sub: ISubscription;

  constructor(private el: ElementRef,
    private _phoneControl: NgControl,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.phoneValidate();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  phoneValidate() {

    this.sub = this._phoneControl.control.valueChanges.subscribe(data => {
      let preInputValue: string = (this._preValue)?this._preValue:'';
      var lastChar: string = preInputValue.substr(preInputValue.length - 1);     
      var newVal = (data)?data.replace(/\D/g, ''):'';
      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;
      if (data && data.length < preInputValue.length) {
        if (preInputValue.length < start) {
          if (lastChar == '-') {
            newVal = newVal.substr(0, newVal.length - 1);
          }
        }
        if (newVal.length == 0) {
          newVal = '';
        }
        else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        this._phoneControl.control.setValue(newVal, { emitEvent: false });
        this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);
      } else {
        var removedD = (data)? data.charAt(start):'';
        if (newVal.length == 0) {
          newVal = '';
        }
        else if (newVal.length <= 3) {
          newVal = newVal.replace(/^(\d{0,3})(.*)/, '$1');
        } else if (newVal.length <= 6) {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
          newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }
        if (preInputValue.length >= start) {
          if (removedD == '-') {
            start = start + 1;
            end = end + 1;
          }
          if (removedD == '-') {
            start = start + 2; // +2 so there is also space char after ')'.
            end = end + 2;
          }
          if (removedD == '-') {
            start = start + 1;
            end = end + 1;
          }
          if (removedD == " ") {
            start = start + 1;
            end = end + 1;
          }
          this._phoneControl.control.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start, end);
        } else {
          this._phoneControl.control.setValue(newVal, { emitEvent: false });
          this.renderer.selectRootElement(this.el).nativeElement.setSelectionRange(start + 2, end + 2);
        }
      }
    });
  }
}