import * as enStaticStrings from '../../../assets/i18n/en.json';
import * as deStaticStrings from '../../../assets/i18n/de.json';
import * as clientStyles from '../../../assets/styles/clientstyles.json';
import * as attributeData from '../../../assets/data/attribute-data.json';
export class AppStrings {
  public static en_app_strings = enStaticStrings;
  public static de_app_strings = deStaticStrings;
  public static clientStyles = clientStyles;
  public static attributeData = attributeData;
}
