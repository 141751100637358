import {ModuleWithProviders, NgModule} from '@angular/core';
import {MaterialModule} from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import { PhoneMaskDirective } from '../../rsa-decider/directive/phoneUS-format.directive';
import { NumberDirective } from '../../rsa-decider/directive/number.directive';
import {NumberDirectiveIncludeZeros} from '../../rsa-decider/directive/number-include-zeros';
import {NgxExampleLibraryModule} from '@ismaestro/ngx-example-library';
import { TransformUpperCaseDirective } from '../../rsa-decider/directive/transform-upper-case.directive'
import { alphaNumericDirective } from '../../rsa-decider/directive/alphanumeric.directive';

@NgModule({
  imports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    NgxExampleLibraryModule,
    
  ],
  declarations:[
    PhoneMaskDirective,
    TransformUpperCaseDirective,
    NumberDirective,
    alphaNumericDirective,
    NumberDirectiveIncludeZeros
  ],
  exports: [
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    NgxExampleLibraryModule,
    PhoneMaskDirective,
    TransformUpperCaseDirective,
    NumberDirective,
    alphaNumericDirective,
    NumberDirectiveIncludeZeros
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        
      ]
    };
  }
}
