import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersIncludeZeros]'
})
export class NumberDirectiveIncludeZeros {

  constructor(private _numberControl: NgControl) { }

  ngOnInit() {
    this.numberValidater();
  }

  numberValidater() {
    var newVal = '';
    this._numberControl.control.valueChanges.subscribe(data => {
      newVal = (data) ? data.replace(/\D/g, '') : '';
      this._numberControl.control.setValue(newVal, { emitEvent: false });
    });
  }
}
