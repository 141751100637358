import { Injectable } from '@angular/core';
import { AppConfig } from '../../config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoggerService } from '../shared/logger.service';
import { RsaDeciderService } from '../../rsa-decider/shared/rsadecider-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorRsaresolverService {  

  constructor(private http: HttpClient, private actroute: ActivatedRoute, public router: Router,public RsaDeciderService: RsaDeciderService) {
  

   }

  resolve(route: ActivatedRouteSnapshot, rsstate: RouterStateSnapshot,actroute: ActivatedRoute) {
       
    let rsaHelpRequestId = this.RsaDeciderService.getRsaHelpRequestId();
      if (rsaHelpRequestId && rsaHelpRequestId != "") {        
        let apiUrl = environment.AppConfig.RSAHELP.url + rsaHelpRequestId;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': environment.AppConfig.RSAHELP.apiKey,
            'apiPurpose': 'Getting RSAHELP Request Data'
          })
        };  
        
        return this.http.get(apiUrl,httpOptions).pipe(
          tap(heroes => LoggerService.log(`fetched heroes`)),
          catchError(this.handleError())
        );
      
    }
    
  }

   handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //If API return any error, navigating to login page by removing the rsahelprequestid only for rsahelprequestid flow
      this.RsaDeciderService.setRsaHelpRequestId("");
      this.router.navigate(['/error']);

      return of(result as T);
    };
  }
  
}
