import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberDirective {

  constructor(private _numberControl: NgControl) { }

  ngOnInit() {
    this.numberValidate();
  }

  numberValidate() {
    var newVal = '';
    this._numberControl.control.valueChanges.subscribe(data => {
      if (data && data.length == 1) {
        if (data == "0") {
          this._numberControl.control.setValue(newVal, { emitEvent: false });
        } else {
          newVal = (data) ? data.replace(/\D/g, '') : '';
          this._numberControl.control.setValue(newVal, { emitEvent: false });
        }
      } else {
        newVal = (data) ? data.replace(/\D/g, '') : '';
        this._numberControl.control.setValue(newVal, { emitEvent: false });
      }
    });
  }
}
