import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RsaDeciderService } from "./rsadecider-service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RsachildService {
  public behaviourSubject = new BehaviorSubject(false);
  constructor(
    private RsaDeciderService: RsaDeciderService,
    private titleService: Title,
    private router: Router,
    private _translateService: TranslateService
  ) {}

  
  processResolverData(res) {
    if (this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasTrackingId) {
      if (
        res &&
        res.length > 1 &&
        res[1].RequestDetails &&
        this.RsaDeciderService.rsaProgressData.browserRefresh
      ) {
        this.RsaDeciderService.processRsaHelpRequest(res[1].RequestDetails);
        if (res[1].RSARequestId) this.router.navigate(["/submit-success", this.RsaDeciderService.getRsaTrackingId()]);
      }
    }
    // if (res && res.length > 1) {  // enable when multi client option is enabled and modify the resolver service
    //   this.multiClient = res;
    // }
    this.RsaDeciderService.setRsaConfig(res);
    if (res && res[0].name) {
      this.titleService.setTitle(res[0].name);
    }
    this._translateService.use(this.RsaDeciderService.appDataConfig.programcode == "ADC" ? 'de' : 'en');
  }
}
