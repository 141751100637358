import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: 'input[alphaNumericOnly]'
})
export class alphaNumericDirective {
    @Input() specialChar;
    constructor(private _numberControl: NgControl) { }

    ngOnInit() {
        console.log(this.specialChar)
        this.alphaNumericValidate();
    }

    alphaNumericValidate() {
        if (!this.specialChar) {
            this._numberControl.control.valueChanges.subscribe(data => {
                var newVal = (data) ? data.replace(/[^A-Za-z0-9]/, '') : '';
                this._numberControl.control.setValue(newVal, { emitEvent: false });
            });
        } else {
            this._numberControl.control.valueChanges.subscribe(data => {
                var newVal = (data) ? data.replace(/[^A-Za-z0-9 ]/, '') : '';
                this._numberControl.control.setValue(newVal, { emitEvent: false });
            });
        }
    }
}
