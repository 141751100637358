import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rsa-alert-dialog',
  templateUrl: './rsa-alert-dialog.component.html'
})
export class RsaAlertDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MatDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.cdr.detectChanges();
  }

}
