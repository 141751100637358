import { Injectable } from "@angular/core";
import { AppConfig } from "../../config/app.config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Observable,
  of,
  throwError as observableThrowError,
  forkJoin
} from "rxjs";
import { catchError, tap, mergeMap, map } from "rxjs/operators";
import { LoggerService } from "../../core/shared/logger.service";
import { RsaDeciderService } from "./rsadecider-service";
import { GoogleAnalyticsEventsService } from "src/app/google-analytics-events.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class RsaresolverService {
  apiUrl = "../../assets/data/api-data.json";
  params;

  constructor(
    private http: HttpClient,
    private actroute: ActivatedRoute,
    public router: Router,
    private RsaDeciderService: RsaDeciderService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    rsstate: RouterStateSnapshot,
    actroute: ActivatedRoute
  ) {
    this.params = rsstate.url;
    // let clientId = window.location.href.split("?num=")[1];
    let clientId, callBackNumber, vdn, audioType;
    // let estDateTime, estDateTimeWithTimeZone;
    let paramString = window.location.search;
  
    this.removeLocalStorageItems();

    // paramstring contains ?num - non-ivr flow
    if (/num/.test(paramString)) {
      let numString = paramString.split("&")[0];
      clientId = numString.split("?num=")[1];
      if(clientId == "tms" || clientId == "TMS"){
        this.router.navigate(["/info"], { queryParams: { id: clientId } });
      }
    }
    /*if (/id/.test(paramString)) {
      let encodedData = paramString.split("id=")[1];
      let splittedArray = atob(encodedData).split(":");
      // let splittedArray = encodedData.split(":");
      callBackNumber = splittedArray[0];
      clientId = splittedArray[1];
      vdn = splittedArray[2];
      // audioType = splittedArray[3] || 3;
      // if(splittedArray[3] && splittedArray[4] && splittedArray[5]){
      //   estDateTime = splittedArray[3]+":"+splittedArray[4] +":"+ splittedArray[5];
      // } else {
      //   estDateTime = null;
      // }
      // estDateTimeWithTimeZone = this.RsaDeciderService.addingEasternTimeZone(splittedArray);
    } */

    if (paramString.includes("adID") || paramString.includes("deviceID")) {
      this.RsaDeciderService.rsaProgressData.httpUrlSearch = window.location.search.replace(
        "?",
        ""
      );
      let adIdString = paramString.split("&");
      adIdString.forEach(name => {
        if (name.includes("adID")) {
          this.RsaDeciderService.rsaProgressData.splunkInfo.adID = name.split(
            "adID="
          )[1];
        }
        if (name.includes("deviceID")) {
          this.RsaDeciderService.rsaProgressData.splunkInfo.deviceID = name.split(
            "deviceID="
          )[1];
        }
      });
    }

    // if (/id/.test(paramString) && callBackNumber && clientId && vdn) {
    //   this.processIvrFlow(callBackNumber, vdn);
    // }

    let hostname =
      window.location.hostname != "localhost" ? window.location.hostname : "";

    if (JSON.parse(localStorage.getItem("isRsaHelpRequestIdFlow"))) {
      this.RsaDeciderService.rsaProgressData.splunkInfo.callType = "Client";
      localStorage.setItem("isRsaHelpRequestIdFlow", "false");
    }

    // hostname = "usaa.towupload.com";
    let domainName = hostname.split(".");
    if (clientId && clientId != null && clientId.trim() != "" && clientId != "www") {
      JSON.stringify(localStorage.setItem("nonIvrFlow", "true"));
      let apiUrl = environment.AppConfig.ClientDetailsUrl.clienturl + clientId.toUpperCase();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
          'apiPurpose': 'Getting Client details'
        })
      };
      return this.http.get(apiUrl,httpOptions).pipe(
        tap(heroes => LoggerService.log(`fetched heroes`)),
        catchError(this.handleError())
      );
    } else if ( // paramstring contains domain name - non-ivr flow
      domainName.length > 2 &&
      domainName[0] !== "www" &&
      !this.params.split("/")[2]
    ) {
      let clientId = domainName[0];
      if(clientId == "toyota"){
        this.router.navigate(["/info"],{ queryParams: { id: clientId } });
      }
      if (clientId && clientId != null && clientId.trim() != "") {
        JSON.stringify(localStorage.setItem("nonIvrFlow", "true"));
        this.RsaDeciderService.nonAPISplunkLog(
          "Landing Page- Default CallType"
        );
        let apiUrl = environment.AppConfig.ClientDetailsUrl.clienturl + clientId;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
            'apiPurpose': 'Getting Client details'
          })
        };

        // paramstring contains domain name with rsaHelpRequestId "ford.towupload.com/?rsahelpRequestId=??"
        let rsaHelpRequestId = this.RsaDeciderService.getRsaHelpRequestId();
        if (rsaHelpRequestId && rsaHelpRequestId != "") {
          return this.getRequestDetails(rsaHelpRequestId);
          // let helpApiUrl = environment.AppConfig.RSAHELP.url + rsaHelpRequestId;
          // const helphttpOptions = {
          //   headers: new HttpHeaders({
          //     'Content-Type': 'application/json',
          //     'Authorization': environment.AppConfig.RSAHELP.apiKey
          //   })
          // };

          // this.RsaDeciderService.getRsaHelpRequestData(helpApiUrl, helphttpOptions).subscribe((data) => {
          //   var response: any = data;
          //   localStorage.setItem("rsaHelpRequest", response);
          // });

          // return this.http.get(apiUrl).pipe(
          //   tap(heroes => LoggerService.log(`fetched heroes`)),
          //   catchError(this.handleError())
          // );
          // this.router.navigate(["/error"]);
        } else {
          return this.http.get(apiUrl, httpOptions).pipe(
            tap(heroes => LoggerService.log(`fetched heroes`)),
            catchError(this.handleError())
          );
        }
      } else {
        this.router.navigate(["/error"]);
      }
    } 
    // paramstring contains trackingID - IVR flow
    else if (/id/.test(paramString)) {
      JSON.stringify(localStorage.setItem("nonIvrFlow", "false"));
      let trackingId = paramString.split("id=")[1];
      return this.getTrackingIdDetails(trackingId, "trackingIdAsQueryParam");
    } 
    // paramstring contains rsaHelpRequestID - non-ivr flow
    else if (/rsahelpRequestId/.test(paramString)) {
      JSON.stringify(localStorage.setItem("nonIvrFlow", "true")); 
      let rsahelpRequestId = paramString.split("rsahelpRequestId=")[1];   
      return this.getRequestDetails(rsahelpRequestId);
    } 
    else {      
      let trackingId = this.params.split("/")[2];
      let lyft = this.params.split("/")[1];
      if (trackingId) {
        this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasTrackingId = true;
        return this.getTrackingIdDetails(trackingId, "trackingIdAsParam");
      } else {
        if(lyft !== "lyft")
        this.router.navigate(["/error"]);
      }
    }
  }

  handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      this.router.navigate(["/error"]);
      return of(result as T);
    };
  }
  processIvrFlow(callBackNumber, vdn) {
    // this.RsaDeciderService.rsaProgressData.query_strings.estDateTime = estDateTime; // include estDateTime parameter when it is uncommented
    //Enable IVR mode
    this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasCallBack = true;
    this.RsaDeciderService.rsaProgressData.splunkInfo.callType = "IVR";
    let callBackNumberOnlyNumber = callBackNumber.replace(/[^0-9]+/gi, "");
    // this.RsaDeciderService.rsaProgressData.audio_type = (!isNaN(audioType) && audioType < 4) ? audioType : 3;
    this.RsaDeciderService.setVdn(vdn);
    if (callBackNumberOnlyNumber.length === 10) {
      let numberUSformat =
        callBackNumberOnlyNumber.substring(0, 3) +
        "-" +
        callBackNumberOnlyNumber.substring(3, 6) +
        "-" +
        callBackNumberOnlyNumber.substring(6, 10);
      this.RsaDeciderService.setPhoneNumber(numberUSformat);
    }
    // let tempTime = this.RsaDeciderService.convertESTtoCorrespondingTimeZone(estDateTimeWithTimeZone);
    // console.log(tempTime.hours() +":"+ tempTime.minutes() +":"+ tempTime.seconds());
    // if(tempTime.seconds() > 0){
    //   //Link expired
    //   this.RsaDeciderService.rsaProgressData.audio_type = 2;
    //   clientId = null;
    // } else if(tempTime.seconds() < 0) {
    //   //Link is active
    //   this.RsaDeciderService.rsaProgressData.audio_type = 0;
    // }
  }

  processTrackingDetails(trackingDetails) {
    let requestDetails = trackingDetails[0].RequestDetails;
    let callType = requestDetails.additionalDetails.callType;
    switch (callType) {
      case "IVR":
        this.RsaDeciderService.rsaProgressData.splunkInfo.callType = "IVR";
        this.processIvrFlow(
          requestDetails.requestor.callbackNumber,
          requestDetails.vdn
        );
        JSON.stringify(localStorage.setItem("nonIvrFlow", "false"));
        break;
      case "Client":
        this.RsaDeciderService.rsaProgressData.splunkInfo.callType = "Client";
        this.RsaDeciderService.rsaProgressData.trackingIdFlags.hasRsaHelpRequestId = true;
        this.RsaDeciderService.rsaProgressData.step1.rsahelpRequestId =
          trackingDetails[0].RequestDetails.rsaHelpRequestId;
        JSON.stringify(localStorage.setItem("nonIvrFlow", "true"));
        break;
      case "default":
        JSON.stringify(localStorage.setItem("nonIvrFlow", "true"));
        break;
    }
  }

  getTrackingIdDetails(trackingId, decider) {
    this.RsaDeciderService.setRsaTrackingId(trackingId);
    let mergedObservable = this.RsaDeciderService.getTrackingDetailsAndID(
      trackingId
    ).pipe(
      catchError(this.handleError()),
      mergeMap(trackingDetails => {
        this.RsaDeciderService.setEscalationCallType(trackingDetails[0].EscalationCallType);
        if (trackingDetails && trackingDetails[0]) {
          if(trackingDetails[0].ProgramCode == "tms" || trackingDetails[0].ProgramCode == "TMS"){
            this.router.navigate(["/info"], { queryParams: { id: trackingDetails[0].ProgramCode } });
          }
          this.RsaDeciderService.rsaProgressData.isInitialLoad = trackingDetails[0].RequestDetails == null;
        //Retrieve programsubcode from trackingdetails for PRG client
          if(trackingDetails[0].programSubCode){
            this.RsaDeciderService.rsaProgressData.programsubcode = trackingDetails[0].programSubCode;
          }
          if(trackingDetails[0].PlatformId){
            this.RsaDeciderService.platformId = trackingDetails[0].PlatformId;
          }
          if(trackingDetails[0].TransitionEndState){
            this.RsaDeciderService.transitionEndState = trackingDetails[0].TransitionEndState;
          }
          if (
            trackingDetails[0].RequestDetails &&
            trackingDetails[0].RequestDetails.additionalDetails &&
            decider === "trackingIdAsParam"
          ) {
            this.processTrackingDetails(trackingDetails);
          }
          if (decider === "trackingIdAsQueryParam") {
            if (trackingDetails[0].CallBackNo) {
              this.processIvrFlow(
                trackingDetails[0].CallBackNo,
                trackingDetails[0].Vdn
              );
     
              this.RsaDeciderService.liveCallStatus =
                trackingDetails[0].isCallActive;
            } else {
              //considering this as normal flow since callback or vdn is missing
              this.RsaDeciderService.rsaProgressData.step1.rsaRequestId = trackingDetails[0].RSARequestId;
            }
            // this.RsaDeciderService.setRsaTrackingId(trackingDetails.TrackingId);
          }
        
          if (trackingDetails[0].ProgramCode) {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
                'apiPurpose': 'Getting Client details'
              })
            };
            return forkJoin(
              this.http
                .get(
                  environment.AppConfig.ClientDetailsUrl.clienturl +
                    trackingDetails[0].ProgramCode, httpOptions
                )
                .pipe(
                  map(clientDetails => clientDetails[0]),
                  catchError(this.handleError())
                ),
              of(trackingDetails[0])
            );
          } else {
            this.router.navigate(["/error"]);
            return of();
          }
        } else {
          this.router.navigate(["/error"]);
          return of();
        }
      })
    );
    return mergedObservable;
  }

  getRequestDetails(rsaHelpRequestId) {
    let mergedObservable = this.RsaDeciderService.getRequestDetails(rsaHelpRequestId).pipe(
      catchError(this.handleError()),
      mergeMap((requestDetails: any) => {
        this.googleAnalyticsEventsService.emitEvent("Landing Screen", "RSA-HELP-REQUEST | " + requestDetails.programCode, "RsaHelpRequestId | " + requestDetails.rsahelpRequestId, 10);
        localStorage.setItem("rsaHelpRequest", JSON.stringify(requestDetails));
        localStorage.setItem("isRsaHelpRequestIdFlow", requestDetails.rsahelpRequestId !== "" ? "true" : "false");
        if (JSON.parse(localStorage.getItem("isRsaHelpRequestIdFlow"))) {
          this.RsaDeciderService.rsaProgressData.splunkInfo.callType = "Client";
        }
        if (requestDetails && requestDetails.programCode) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization':  environment.AppConfig.ClientDetailsUrl.clientapikey,
              'apiPurpose': 'Getting Client details'
            })
          };
          return forkJoin(
            this.http
              .get(
                environment.AppConfig.ClientDetailsUrl.clienturl +
                requestDetails.programCode, httpOptions
              )
              .pipe(
                map(clientDetails => clientDetails[0]),
                catchError(this.handleError())
              ),
            of(requestDetails)
          );
        } else {
          this.router.navigate(["/error"]);
          return of();
        }
      })
    );
    return mergedObservable;
  }

  removeLocalStorageItems() {
    localStorage.removeItem('nonIvrFlow');
    localStorage.removeItem('isRsaHelpRequestIdFlow');
    localStorage.removeItem('rsaHelpRequest');
  }
}
